import { Button } from '@types';

export const getMappedRows = (buttons: Button[]) => {
  const rows = {};
  const res: Button[][] = [];

  buttons.forEach((button) => {
    if (rows[button.row]) {
      rows[button.row].push(button);
    } else {
      rows[button.row] = [button];
    }
  });

  for (const rowIndex in rows) {
    res[rowIndex] = rows[rowIndex];
  }
  return res;
};
