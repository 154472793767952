export const MESSAGE_SENDER = {
  CLIENT: 'client',
  RESPONSE: 'response'
};

export const MESSAGES_TYPES = {
  TEXT: 'text',
  SNIPPET: {
    LINK: 'snippet',
  },
  CUSTOM_COMPONENT: 'component'
};

const SOCKET_PROTOCOL = location.protocol === 'https:' ? 'wss://' : 'ws://'

export const SOCKET_URL = SOCKET_PROTOCOL + window.location.host + process.env.SOCKET_URL;
export const SERVER_URL = window.location.origin + process.env.SERVER_URL;
export const DOWNLOAD_PATH = window.location.origin + process.env.DOWNLOAD_URL;
export const WIDGET_TOKEN = process.env.WIDGET_TOKEN;
export const MESSAGE_BOX_SCROLL_DURATION = 400;
export const CONNECTION_ATTEMPTS_LIMIT = 5;
