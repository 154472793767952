import { toggleMsgLoader } from '../store/actions'
import { SOCKET_URL, WIDGET_TOKEN, SERVER_URL, CONNECTION_ATTEMPTS_LIMIT } from "../constants";
import store from "../store";
import { getClientId, setClientId } from './../localstorage/room';

let socket;

enum statuses {
    "CONNECTING",
    "STARTED"
}
interface MessageForSend {
    message: string,
    type: "message"
}
interface MessageForAccept {
    message: string,
    type: "message" | "error"
}
interface MessageFirst {
    url: string,
    token: string
}
class ChatSocket {
    SOCKET_URL: string;
    socket: WebSocket | null;
    status: statuses;
    room_id: string | null;
    client_id: string | null;
    awaitConnection: boolean;
    connectionAttempts: number;
    handler: ((Object) => void) | null;

    constructor(url) {
        this.status = statuses.CONNECTING;
        this.handler = null;
        this.room_id = null;
        this.client_id = null;
        this.SOCKET_URL = url;
        this.socket = null;
        this.awaitConnection = false;
        this.connectionAttempts = 0;
    }

    connect(room_id: string, client_id: string) {
        if (!WIDGET_TOKEN) {
            throw new Error('Widget token is undefined. Check .env')
        }
        if (!SERVER_URL) {
            throw new Error('Widget SERVER_URL is undefined. Check .env')
        }

        const firstMessage: MessageFirst = {
            token: WIDGET_TOKEN,
            url: SERVER_URL
        }

        this.awaitConnection = true

        let client_id_from_LS = getClientId()

        if (!client_id_from_LS) {
            setClientId(Date.now().toString())
            client_id_from_LS = getClientId()
        }

        const socket = new WebSocket(`${SOCKET_URL}/${client_id_from_LS}`);

        this.socket = socket;

        const connect = () => this.connect(room_id, client_id);
        const handler = this.handler ? this.handler : event => console.error("Message from socket without handler function: ", event);

        socket.onopen = () => {
            this.status = statuses.STARTED
            this.awaitConnection = false
            socket.send(JSON.stringify(firstMessage))
        }

        socket.onerror = function (error) {
            console.dir(error);
            console.error(error);
        };

        socket.onclose = () => {
            this.connectionAttempts++
            if (!this.awaitConnection && !(this.connectionAttempts > CONNECTION_ATTEMPTS_LIMIT)) {
                connect()
            } else {
                throw new Error('Connection failed. Check url and token')
            };
            this.awaitConnection = false
        }

        socket.onmessage = function (event) {
            // console.log('socket event: ', event)
            const data = JSON.parse(event.data)
            if (store.getState().behavior.messageLoader) {
                toggleMsgLoader()
            }
            try {
                handler(data)
            } catch (error) {
                console.error('Error in handler: ', event)
            }
        };
        return socket
    }

    reconnectToNew() {
        this.socket?.close();
        const newClientId = Math.random().toString().slice(2);
        setClientId(newClientId);
        this.client_id = newClientId;
        this.socket = this.connect('', newClientId);
    }

    public subscribe(handler: (Object) => any) {
        this.handler = handler
    }

    public async sendMessage(message: string) {

        const messageObject: MessageForSend = { type: "message", message }

        if (this.socket) {

            // temp
            // @ts-ignore
            if (message instanceof Blob) {
                this.socket.binaryType = "arraybuffer";

                const arrayBufferMessage = await message.arrayBuffer()

                this.socket.send(arrayBufferMessage)
            } else {
                // console.log('sendingMessage string', message);


                this.socket.send(JSON.stringify(messageObject))
            }

            // temp



            // @ts-ignore
            // this.socket.send(JSON.stringify({
            //     "content": message,
            //     "datetime": new Date(),
            //     "type": "message"
            // }));

            // if(this.socket){
            //     console.log('sendingMessage', message);

            //     this.socket.send(JSON.stringify({
            //         "content": message,
            //         "datetime": new Date(),
            //         "type" : "message"
            //     }));
        } else {
            console.error("Message from socket without socket connections: ", message);
        }
    }

}

export default new ChatSocket(`${SOCKET_URL}`);