import format from 'date-fns/format';

import { MessageTypes } from 'src/store/types';

import './styles.scss';
import { DOWNLOAD_PATH } from '../../../../../../../../constants';
import { MessageButtons } from './components/MessageButtons';
import Linkify from 'linkify-react';

type Props = {
  message: MessageTypes;
  showTimeStamp: boolean;
  sendMessage: (message: string) => void;
}

const getDownloadURI = (fileName: string) => {
  return DOWNLOAD_PATH + fileName
}

function Message({ message, showTimeStamp, sendMessage }: Props) {

  const isClient = message.sender === 'client'

  return (
    <>
    <div className={`rcw-${message.sender}`}>
      <div className="rcw-message-text">
        <div className="rcw-message-text-wrapper">
          {/* @ts-ignore */}
          <Linkify options={{ target: '__blank' }}>{message.text}</Linkify>
          {message?.files && message.files.map(el =>
            <div className='rcw-message-link-wrapper'>
              <a className='rcw-message-link' href={getDownloadURI(el)}>{el}</a>
            </div>)}
        </div>
      </div>    

      {showTimeStamp && <span className="rcw-timestamp">{format(message.timestamp, 'HH:mm')}</span>}
    </div>
    {!isClient && (
        <MessageButtons
          sendMessage={sendMessage}
          buttons={message?.buttons || []}
        />
      )}
    </>

  );
}

export default Message;
