import { Component } from 'react';
//@ts-ignore
import logo from '../assets/logo.svg'
import {
  Widget, addTranscriptionMessage, toggleMsgLoader, addLinkSnippet, addUserMessage,
  addResponseMessage,
} from '../index';
import React from 'react';
import socket from '../src/socket/socket';

export default class App extends Component {
  componentDidMount() {
    // addResponseMessage("Здравствуйте! Что вас интересует?")

    socket.subscribe(e => {
      if (e.type == "message") {
        addResponseMessage({ text: e.message, buttons: e?.buttons ?? [], files: e?.files ?? [] })
      }

      // in this version we have only 2 type of message: "message"/"error"
      if (e.content && e.type == "transcription") addTranscriptionMessage({
        text: e.content,
        final: e.final
      })
      if (e.type === 'history' && e.messages && Array.isArray(e.messages)) {
        e.messages.forEach(message => {
          if (message.content) {
            if (message.side == 2) {
              addUserMessage(message.content, message.datetime)
            } else {
              addResponseMessage({ text: message.content, buttons: message?.buttons ?? [], files: e?.files ?? [] }, message.datetime)
            }
          }
        })
      }
    });
    // addLinkSnippet({ link: 'https://google.com', title: 'Google' });

  }

  handleNewUserMessage = (newMessage: any) => {
    toggleMsgLoader();
  }

  handleQuickButtonClicked = (e: any) => {
    addResponseMessage({ text: 'Selected ' + e, buttons: [], files: [] });
  }

  handleSubmit = (msgText: string) => {

    return true;
  }

  render() {
    return (
      //@ts-ignore
      <Widget
        subtitle="Chat"
        senderPlaceHolder="Напишите сообщение..."
        handleNewUserMessage={this.handleNewUserMessage}
        handleQuickButtonClicked={this.handleQuickButtonClicked}
        imagePreview
        handleSubmit={this.handleSubmit}
        emojis
      />
    );
  }
}
