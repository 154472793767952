import { getMappedRows } from "./helpers";
import "./style.scss";

import { Button } from "@types";

export const MessageButtons = ({ sendMessage, buttons }: { sendMessage: any, buttons: Button[] }) => {

  const handleClick = (text: string) => () => {
    sendMessage(text)
  }

  if (!buttons?.length) return null

  const mappedRows = getMappedRows(buttons);

  return <div className="rcw-buttons">
    <div className="rcw-buttons-container">
      {mappedRows.map((row) => (
        <div className="rcw-buttons-row">
          {row.map((button, index) => (
            <button
              className={`rcw-button-item rcw-button-item--${button?.type || 'primary'}`}
              key={index}
              onClick={handleClick(button.tag)}
            >
              {button.emoji} {button.label}
            </button>
          ))}
        </div>
      ))}
    </div>
  </div>
};
